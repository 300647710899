<template>
    <div>
      <div class="flex md12">
        <va-card :title="'Automatic Replies List'">
          <div class="row align--center">
            <div class="flex xs12 md3">
              <va-input
                :value="term"
                :placeholder="'Search Replies'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 mb-3">
                <!-- create button and allign right -->
                <va-button
                    @click="CreateReply()"
                    color="success"
                >
                    <i
                        class="va-icon iconicstroke iconicstroke-bolt"
                        style="margin-right: 1em;"
                    />  Create Reply
                </va-button>
            </div>

            <div class=" flex xs12 md3 offset--md3">
                <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
  
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            clickable
            hoverable
          >
          <template slot="title" slot-scope="props">
            <span @click="showField('Title', props.rowData.default.title)" :title="props.rowData.default.title" >{{ props.rowData.title }}</span>
          </template>
          <template slot="message" slot-scope="props">
            <span @click="showField('Message', props.rowData.default.message)" :title="props.rowData.default.message" >{{ props.rowData.message }}</span>
          </template>
          <template slot="actions" slot-scope="props">
            <div class="flex row">
                <va-button @click="EditReply(props.rowData)" title="View/Edit Reply"   icon="fa fa-pencil"/>
                <va-button @click="DeleteReply(props.rowData.id)" title="Delete Reply" color="danger" icon="fa fa-trash"/>
            </div>
          </template>
         </va-data-table>
         
        </va-card>
  
      </div>
    </div>
  </template>
  
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  export default {
    data() {
      return {
        email: '',
        admins: [],
        loading: true,
        regradeLoading: false,
        emailErrors: [],
        replies: [],
        term: null,
        perPage: '50',
        perPageOptions: ['10', '50', '100', '250'],
        availableTests: [],
        ticketLabels: [],
      };
    },
    async created() {
     await this.loadData();
    },
    computed: {
      fields() {
        return [
        {
          name: '__slot:title',
          title: 'Title',
          sortField: 'title',
          width: '40%',
        },
        {
          name: '__slot:message',
          title: 'Message',
          sortField: 'formatedMessage',
          width: '40%',
        },
        {
        name: '__slot:actions',
        title: 'Actions',
        width: '20%',
        },
        ];
      },
      filteredData() {
        if (!this.term || this.term.length < 1) {
          //order by updatedAt  or createdAt, if createdAt is not available
          this.replies.sort((a, b) => {
                if (a.updatedAt && b.updatedAt) {
                  return b.updatedAt - a.updatedAt;
                }
                return b.createdAt - a.createdAt;
          });
          return this.replies;
        }
        return this.replies.filter((item) => {
          let returnUser = false;
          if (item.user) {
            if (item.user.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnUser = true;
            }
          }
          return returnUser;
        });
      },
    },
    methods: {
      async loadData(){
       this.loading = true;
       this.replies = [];
       let replies = await this.$db.collection('evalTicketReplies').get();
       replies.forEach(async (exam) => {
            //if exam.data has more than 4 characters, then add ... to the end
            let title = exam.data().title;
            let message = exam.data().message;

            if(title.length > 30)
              title = title.substring(0, 30) + '...';

            if(message.length > 30 )
              message = message.substring(0, 30) + '...';
           
            this.replies.push({
              id: exam.id,
              title: title,
              message: message,
              default: {
                title: exam.data().title,
                message: exam.data().message,
              }
            });
       });
       this.loading = false;
      },
      showField(title, text) {
        //show swal with the text
        this.$swal({
          title: title,
          icon: 'info',
          html: text,
          confirmButtonText: 'Close',
        });
      },
      CreateReply(){
            //open swal with 3 inputs, title,  message
            //on submit, create in db with the new values
            //on cancel, do nothing
            this.$swal({
              title: 'Create Reply',
              html:
                `<input id="swal-input1" class="swal2-input" placeholder="Title">` +
                `<textarea id="swal-input2"style="resize: none; min-height: 100px; padding-top: 13px ;" class="swal2-input" placeholder="Message"></textarea>`,
              focusConfirm: false,
              confirmButtonText: 'Create',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              preConfirm: () => {
                return [
                  document.getElementById('swal-input1').value,
                  document.getElementById('swal-input2').value,
                ]
              }
            }).then(async (result) => {
              if (result.value) {
                //check if any of the inputs are empty
                if(result.value[0] == '' || result.value[1] == ''){
                  this.$swal({
                    icon: 'error',
                    title: 'Please fill out all fields',
                    showConfirmButton: true,
                    timer: 1500
                 });
                 return;
                }
                this.$swal.fire({
                  title: 'Saving Data',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: async () => {
                     this.$swal.showLoading();
                     //create new reply
                     await this.$db.collection('evalTicketReplies').add({
                            title: result.value[0],
                            message: result.value[1],
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                      this.$swal({
                          icon: 'success',
                          title: 'Reply Created',
                          showConfirmButton: true,
                          timer: 1500
                      }).then(async () => {
                          await this.loadData();
                      });
                  },
                })
              }
            });
      },
      EditReply(data) {
            //open swal with 3 inputs, title, message
            //on submit, update the db with the new values
            //on cancel, do nothing
            this.$swal({
              title: 'Edit Reply',
              html:
                `<input id="swal-input1" value="${data.default.title}" class="swal2-input" placeholder="Title">` +
                `<textarea id="swal-input2" style="resize: none; min-height: 100px; padding-top: 13px ;" class="swal2-input" placeholder="Message">${data.default.message}</textarea>`,
              focusConfirm: false,
              confirmButtonText: 'Update',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              preConfirm: () => {
                return [
                  document.getElementById('swal-input1').value,
                  document.getElementById('swal-input2').value,
                ]
              }
            }).then(async (result) => {
              if (result.value) {

                //check if any of the inputs are empty
                if(result.value[0] == '' || result.value[1] == ''){
                  this.$swal({
                    icon: 'error',
                    title: 'Please fill out all fields',
                    showConfirmButton: true,
                    timer: 1500
                 });
                 return;
                }

                this.$swal.fire({
                  title: 'Saving Data',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: async () => {
                     this.$swal.showLoading();
                      await this.$db.collection('evalTicketReplies').doc(data.id).update({
                        title: result.value[0],
                        message: result.value[1],
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                      this.$swal.close();
                  },
                }).then((result) => {
                  this.$swal({
                    icon: 'success',
                    title: 'Reply Updated',
                    showConfirmButton: true,
                    timer: 1500
                 }).then(async () => {
                    await this.loadData();
                 });
                })
              }
            });
      },
      DeleteReply(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                this.$swal.fire({
                title: 'Deleting Reply',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: async () => {
                     this.$swal.showLoading();
                     await this.$db.collection('evalTicketReplies').doc(id).delete();
                     this.$swal.close();
                },
                }).then((result) => {
                this.$swal({
                    icon: 'success',
                    title: 'Reply Deleted',
                    showConfirmButton: true,
                    timer: 1500
                 }).then(async () => {
                    await this.loadData();
                 });
                })
            }
        })
      },    
      search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
      }, 100),
    }
  };
  </script>
  